import React from 'react'
import styled from 'styled-components'
import App from 'App'
import { COLOR_CONSTANTS, radius, colors, lineHeights } from 'theme'
import {
  CONTAINER_MAX_WIDTH,
  FREQUENTLY_ASKED_QUESTIONS,
  PUBLISHING,
  SITE_LINK_PUBLISHING,
  SITE_LINK_BOOSTING,
} from 'consts'
import SEO from 'components/SEO'
import { Flex, Grid, Box } from 'components/Layout'
import Container from 'components/Container'
import { Text, H1, H2, H3 } from 'components/Typography'
import Footer from 'components/Footer'
import LocalImage from 'components/LocalImage'
import GetStartedComponent from 'components/GetStartedComponent'
import Link from 'components/Link'
import G2FeaturesComponent from 'components/G2FeaturesComponent'
import CarouselReviewSection from 'routes/Home/components/CarouselReviewSection'
import SelectPlanButton from 'routes/Home/components/SelectPlanButton'
import SocialMediasSection from 'routes/Home/components/SocialMediasSection'
import QuestionAnswerComponent from 'routes/Faq/QuestionAnswerComponent'
import GridDropdown from 'routes/Faq/GridDropdown'

import imageAIAssistant from 'static/images/ai_assistant/image_generate_social_posts.webp'

import imagePublishFeatures from 'static/images/publish/perfect_post.webp'
import imageMediaLibrary from 'static/images/publish/media_library.webp'
import imageUGC from 'static/images/publish/ugc.webp'
import imageBrowserExtension from 'static/images/publish/browser_extension.webp'
import imageCollaborateOnContent from 'static/images/publish/collaborate_on_content.webp'
import imagePlanPostingSchedule from 'static/images/publish/plan_posting_schedule.webp'
import imagePostScheduleAndPlan from 'static/images/publish/post_schedule_and_plan.webp'
import imageDoubleArrowRightBlue from 'static/images/home/double_arrow_right_blue.svg'

import networkImageFacebook from 'static/images/networks/facebook.svg'
import networkImageInstagtam from 'static/images/networks/instagram.svg'
import networkImageLinkedIn from 'static/images/networks/linkedin.svg'
import networkImageTwitter from 'static/images/networks/twitter.svg'
import networkImageGoogle from 'static/images/networks/google.svg'
import networkImageTikTok from 'static/images/networks/tiktok.svg'
import networkImageYouTube from 'static/images/networks/youtube.svg'
import networkImageReddit from 'static/images/networks/reddit.svg'
import networkImagePinterest from 'static/images/networks/pinterest.svg'
import networkImageTumblr from 'static/images/networks/tumblr.svg'
import networkImageSnapchat from 'static/images/networks/snapchat.svg'

import mediaLibraryImageBulk from 'static/images/publish/media_library/bulk.svg'
import mediaLibraryImageCanva from 'static/images/publish/media_library/canva.svg'
import mediaLibraryImageLabels from 'static/images/publish/media_library/labels.svg'
import mediaLibraryImageOrganize from 'static/images/publish/media_library/organize.svg'

import collaborateOnContentImageDraftPosts from 'static/images/publish/collaborate_on_content/draft_posts.svg'
import collaborateOnContentImageApproval from 'static/images/publish/collaborate_on_content/approval.svg'
import collaborateOnContentImagePermissions from 'static/images/publish/collaborate_on_content/permissions.svg'
import collaborateOnContentImageNotifications from 'static/images/publish/collaborate_on_content/notifications.svg'

import featureImageCalendar from 'static/images/publish/features/calendar.svg'
import featureImageComment from 'static/images/publish/features/comment.svg'
import featureImageFinder from 'static/images/publish/features/finder.svg'
import featureImageIntegrations from 'static/images/publish/features/integrations.svg'
import featureImageLabel from 'static/images/publish/features/label.svg'
import featureImageLike from 'static/images/publish/features/like.svg'
import featureImageQueue from 'static/images/publish/features/queue.svg'
import featureImageScheduling from 'static/images/publish/features/scheduling.svg'
import featureImagePlanning from 'static/images/publish/features/planning.svg'
import featureImageAudio from 'static/images/publish/features/audio.svg'
import featureImageAI from 'static/images/publish/features/ai.svg'
import featureImageTags from 'static/images/publish/features/tags.svg'
import featureImageThumb from 'static/images/publish/features/thumb.svg'
import featureImageExt from 'static/images/publish/features/ext.svg'
import featureImageZapier from 'static/images/publish/features/zapier.svg'
import featureImageMobile from 'static/images/publish/features/mobile.svg'
import featureImageSchedulingBulk from 'static/images/publish/features/scheduling_bulk.svg'
import featureImageTimes from 'static/images/publish/features/times.svg'
import featureImageTracking from 'static/images/publish/features/tracking.svg'
import featureImageWorkflow from 'static/images/publish/features/workflow.svg'

import HeaderImageAnimation from './HeaderImageAnimation'

const Wrapper = styled(Flex)`
  position: relative;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
  background: ${({ bg }) => bg || COLOR_CONSTANTS.WHITE};
`

const StyledLinkText = styled(Text)`
  display: flex;
  align-items: center;
`

const NETWORKS = [
  { image: networkImageFacebook, pagePath: '/integrations/facebook' },
  { image: networkImageInstagtam, pagePath: '/integrations/instagram' },
  { image: networkImageLinkedIn, pagePath: '/integrations/linkedin' },
  { image: networkImageTwitter, pagePath: '/integrations/twitter' },
  { image: networkImageGoogle, pagePath: '/integrations/google' },
  { image: networkImageTikTok, pagePath: '/integrations/tiktok' },
  { image: networkImageYouTube, pagePath: '/integrations/youtube' },
  { image: networkImageReddit, pagePath: '/integrations/reddit' },
  { image: networkImagePinterest, pagePath: '/integrations/pinterest' },
  { image: networkImageSnapchat, pagePath: '/integrations/snapchat' },
  {
    image: networkImageTumblr,
    pagePath:
      'https://support.vistasocial.com/hc/en-us/articles/23458347603227--Web-Tumblr-Publishing-with-Vista-Social',
  },
]

const MEDIA_LIBRARY_ITEMS = [
  {
    picture_url: mediaLibraryImageBulk,
    title: 'Bulk upload',
    description:
      'Sync and store photos ready to be accessed on all devices by you and your team whenever inspiration strikes.',
  },
  {
    picture_url: mediaLibraryImageCanva,
    title: 'Design with Canva',
    description: 'Quickly design awesome visuals using a built in Canva AI integration.',
  },
  {
    picture_url: mediaLibraryImageLabels,
    title: 'Labels',
    description: 'Organize your media with labels so you can quickly find the right photos at the right time!',
  },
  {
    picture_url: mediaLibraryImageOrganize,
    title: 'Organize',
    description: 'Keep your images and videos well organized by associating them with clients, brands or projects.',
  },
]

const UGC_ITEMS = [
  {
    title: `Collect by Instagram hashtags`,
    description:
      'Search for content by hashtag and add it directly into your media library to schedule or repost into your own feed.',
  },
  {
    title: 'Repost high quality images',
    description:
      'No more pixelated photos! Share the original photo with your own followers, without losing any of the quality.',
  },
  {
    title: 'Ensure Proper Photo Credit',
    description:
      'Vista Social adds photo credit directly into the caption box, so you never have to worry about giving proper credit to the original source.',
  },
]

const FEATURE_ITEMS = [
  {
    picture_url: featureImageScheduling,
    title: 'Scheduling',
    description:
      'Import and schedule multiple social posts across different profiles and networks simultaneously. Save time and aid in planning by queuing up various posts in advance.',
    link: SITE_LINK_PUBLISHING,
  },
  {
    picture_url: featureImageScheduling,
    title: 'Boosting',
    description:
      'Boost your posts across Facebook, Instagram, LinkedIn, Pinterest, TikTok and X (Twitter). Test different boost settings to achieve the best ROI.',
    link: SITE_LINK_BOOSTING,
  },
  {
    picture_url: featureImagePlanning,
    title: 'Planning',
    description:
      'Plan and schedule your content visually while previewing how your social profile feed will look once the post is published on Instagram and TikTok.',
  },
  {
    picture_url: featureImageAudio,
    title: 'Trending Audio',
    description:
      'Use trending audio in your videos by selecting from hundreds of carefully curated sounds by our team or by uploading your own.',
  },
  {
    picture_url: featureImageAI,
    title: 'AI Assistant',
    description:
      'Generate engaging social posts and replies tailored to your target audience, in your brand voice, in seconds using Vista Social AI Assistant.',
  },
  {
    picture_url: featureImageTimes,
    title: 'Optimal send times',
    description:
      'Utilize optimization algorithms to automatically schedule content to post at times proven to generate engagement from your social audiences.',
  },
  {
    picture_url: featureImageWorkflow,
    title: 'Approval workflow',
    description:
      'Generate workflows to guide the submission, review, and approval of messages to maintain oversight, safeguard brand standards and simplify collaboration.',
  },
  {
    picture_url: featureImageIntegrations,
    title: 'Media integrations',
    description:
      'Built-in media library to keep your images and videos organized. Easily upload new media and easily find great photos, gifs, and videos to share.',
  },
  {
    picture_url: featureImageFinder,
    title: 'Content finder',
    description:
      'Find timely and engaging content from social media to supplement original content or find inspiration.',
  },

  {
    picture_url: featureImageLike,
    title: 'First like',
    description:
      'Drop a first like on your post to get more engagement on your post! It also makes your post look that much better!',
  },
  {
    picture_url: featureImageQueue,
    title: 'Post queues',
    description:
      'Set up predefined times of when you would like to post for each profile. This allows for lightning-fast, error-free scheduling.',
  },
  {
    picture_url: featureImageComment,
    title: 'Extra comments',
    description:
      'Kickstart your engagement with up to 10 post comments. Keeping all of your hashtags too and starting X (Twitter) threads!',
  },
  {
    picture_url: featureImageCalendar,
    title: 'Shared calendar',
    description:
      'Manage individual posts and campaigns in a collaborative content calendar to improve visibility and facilitate long-term planning.',
  },
  {
    picture_url: featureImageLabel,
    title: 'Post labels',
    description:
      'Group and categorize messages based on workflow, business objectives, and marketing strategies to easily organize content and streamline performance analysis.',
  },
  {
    picture_url: featureImageTracking,
    title: 'URL Tracking',
    description:
      'Append tracking data to links to quickly identify social traffic in Google Analytics and monitor campaigns, referral traffic, and conversions from social posts.',
  },
  {
    picture_url: featureImageSchedulingBulk,
    title: 'Bulk scheduling',
    description: 'Schedule lots of content quickly by uploading spreadsheets, images, videos, or linking an RSS feed.',
  },
  {
    picture_url: featureImageTags,
    title: 'User and Product tags',
    description: 'Create more engaging content by tagging users and products in your Instagram posts.',
  },
  {
    picture_url: featureImageThumb,
    title: 'Custom thumbnails',
    description: 'Attach custom thumbnails to your videos by selecting specific frames or uploading your own.',
  },
  {
    picture_url: featureImageExt,
    title: 'Browser extension',
    description:
      'Share excellent content as you see it, collect photos, videos, and sounds from anywhere on the web to be used in your posts.',
  },
  {
    picture_url: featureImageMobile,
    title: 'Mobile app',
    description:
      'Seamlessly manage social from one mobile app—access your content calendar, social inbox and reports from anywhere at anytime.',
  },
  {
    picture_url: featureImageZapier,
    title: 'Zapier',
    description:
      'Automate content creation and notification using Zapier and connect your Vista Social account to other apps.',
  },
]

const GENERATE_SOCIAL_POSTS_ITEMS = [
  {
    description: 'Whether you’re writing captions, taglines, or posts, AI Assistant will help craft the perfect text.',
  },

  { description: 'Add fact-checked stats to your posts to increase your authority and credibility.' },
  { description: 'Quickly and more accurately translate your text to reach a global audience.' },
  {
    description:
      'Capture the attention of your followers by adding a joke to make your posts more memorable than straightforward marketing messages.',
  },
  { description: 'Stay consistent in tone, style, and messaging across each social profile and network.' },
]

const COLLABORATE_ON_CONTENT_ITEMS = [
  {
    picture_url: collaborateOnContentImageDraftPosts,
    title: 'Draft posts',
    description: 'Create drafts, get feedback, and refine content as a team',
  },

  {
    picture_url: collaborateOnContentImageApproval,
    title: 'Approval',
    description: 'Assign team members to review and approve content',
  },
  {
    picture_url: collaborateOnContentImagePermissions,
    title: 'Permissions',
    description: 'Easily control each team member’s ability to publish and approve',
  },
  {
    picture_url: collaborateOnContentImageNotifications,
    title: 'Notifications',
    description: 'Get instant alerts when content needs to be approved on your phone or desktop',
  },
]

const PLAN_OUT_SCHEDULE_ITEMS = [
  { title: 'Setup schedules', description: 'Create multiple posting schedules for each profile' },
  { title: 'Optimal time', description: 'Posting schedule for each profile is based on optimal time analysis' },
  {
    title: 'Faster scheduling',
    description: 'Simply add scheduled content to be queued up based on your post schedules',
  },
]

const Publish = () => {
  return (
    <App fullHeader>
      <SEO
        title="Everything You Need to Publish on Social Media. Now with AI Assistant powered by ChatGPT"
        description="Easily schedule and optimize every post you schedule and publish to your social media profiles like a pro. Weaponize unique platform features and techniques that will fuel real results!"
        path="/social-media-publishing/"
      />

      <Wrapper>
        <Container
          pl={{ mobile: 'l', tablet: 'l' }}
          pr={{ mobile: 'l', tablet: 'l' }}
          position="relative"
          maxWidth={CONTAINER_MAX_WIDTH}
          width="100%"
          height="100%"
        >
          <Flex flexDirection="column" alignItems="center">
            <Grid
              mt="xl"
              gridTemplateColumns={{ mobile: '1fr', desktop: '1fr 1fr' }}
              gridGap="l"
              width="100%"
              alignItems="center"
            >
              <Flex mb="l" width="100%" justifyContent="center" alignItems="center">
                <Flex width="100%" justifyContent="center" flexDirection="column">
                  <Flex flexDirection={{ mobile: 'row', desktop: 'column' }} flexWrap="wrap" justifyContent="center">
                    <H1
                      fontSize="4xl"
                      fontWeight="bold"
                      color={COLOR_CONSTANTS.DENIM}
                      textAlign={{ mobile: 'center', desktop: 'left' }}
                    >
                      Everything You Need to{' '}
                      <H1
                        fontSize="4xl"
                        fontWeight="bold"
                        color="primary"
                        textAlign={{ mobile: 'center', desktop: 'left' }}
                      >
                        Plan, Publish and Boost{' '}
                      </H1>
                      Content on Social Media
                    </H1>
                  </Flex>
                  <H2
                    mt="m"
                    pt="s"
                    color="secondaryText"
                    fontSize="m"
                    textAlign={{ mobile: 'center', desktop: 'left' }}
                    fontWeight="normal"
                    lineHeight={lineHeights.xxl}
                  >
                    Easily schedule, boost and optimize every post you schedule. Plan and publish to your social media
                    profiles like a pro. Weaponize unique platform features and techniques that will fuel real results!
                    Now powered by ChatGPT!
                  </H2>
                  <Flex
                    flexDirection="column"
                    mt="m"
                    mb="m"
                    pt="m"
                    alignItems={{ mobile: 'center', desktop: 'flex-start' }}
                  >
                    <Text fontSize="m" color="secondaryText" textAlign="left">
                      Publish to:
                    </Text>
                    <Flex mt="s">
                      {NETWORKS.map(({ image, pagePath }, index) => (
                        <Link
                          href={pagePath}
                          mr={{ mobile: index !== NETWORKS.length - 1 ? 'xs' : 0, mobileLarge: 's' }}
                          key={pagePath}
                        >
                          <LocalImage
                            src={image}
                            alt="Your posts for the best performance on each social network."
                            width="32px"
                            height="32px"
                          />
                        </Link>
                      ))}
                    </Flex>
                  </Flex>
                  <SelectPlanButton />
                </Flex>
              </Flex>
              <Flex width="100%" ml="auto" mr={{ mobile: 'auto', desktop: 0 }}>
                <HeaderImageAnimation />
              </Flex>
            </Grid>

            <Flex
              mt={{ mobile: 'l', tablet: 'm' }}
              pt="m"
              flexDirection="column"
              alignItems="center"
              mb={{ mobile: 'l', desktop: 'l' }}
            >
              <G2FeaturesComponent />
            </Flex>
          </Flex>
        </Container>
      </Wrapper>

      <Wrapper bg={COLOR_CONSTANTS.SALT}>
        <Container
          pl={{ mobile: 'l', tablet: 'l' }}
          pr={{ mobile: 'l', tablet: 'l' }}
          py={{ mobile: 'l', tablet: 'xxl' }}
          position="relative"
          maxWidth={CONTAINER_MAX_WIDTH}
          width="100%"
          height="100%"
        >
          <Flex maxWidth="680px" flexDirection="column" mx="auto">
            <H3 fontSize="3xl" fontWeight="bold" color={COLOR_CONSTANTS.DENIM} textAlign="center">
              Craft the Perfect Post for each social network,{' '}
              <H1 as="span" fontSize="3xl" fontWeight="bold" color="primary">
                All in One Place
              </H1>
            </H3>
            <Text mt="m" pt="s" color="secondaryText" fontSize="l">
              Plan, publish and boost your content for Instagram, Facebook, X (Twitter), Pinterest, TikTok, LinkedIn,
              Reddit, Snapchat, Tumblr and YouTube, all from one simple dashboard.
            </Text>
          </Flex>

          <Flex mt="xl" justifyContent={{ mobile: 'center', desktop: 'space-between' }} as="a" href="/pricing">
            <LocalImage
              src={imagePublishFeatures}
              maxWidth="1217px"
              width="100%"
              mx="auto"
              alt="Craft the Perfect Post for each social network, All in One Place"
            />
          </Flex>
        </Container>
      </Wrapper>

      <Wrapper>
        <Container
          pl={{ mobile: 'l', tablet: 'l' }}
          pr={{ mobile: 'l', tablet: 'l' }}
          py={{ mobile: 'l', tablet: 'xxl' }}
          position="relative"
          maxWidth={CONTAINER_MAX_WIDTH}
          width="100%"
          height="100%"
        >
          <Flex maxWidth="800px" flexDirection="column" mx="auto">
            <H3 fontSize="3xl" fontWeight="bold" color={COLOR_CONSTANTS.DENIM} textAlign="center">
              Generate Posts 10x Faster Using{' '}
            </H3>
            <H3 fontSize="3xl" fontWeight="bold" color={COLOR_CONSTANTS.DENIM} textAlign="center">
              <H1 as="span" fontSize="3xl" fontWeight="bold" color="primary">
                AI Assistant powered by ChatGPT
              </H1>
            </H3>
          </Flex>
          <Flex
            mt="l"
            pt="m"
            justifyContent={{ mobile: 'center', desktop: 'space-between' }}
            flexDirection={{ mobile: 'column', desktop: 'row' }}
            alignItems="center"
          >
            <Flex mt={{ mobile: 'l', desktop: '0' }} maxWidth="640px" width="100%">
              <LocalImage
                src={imageAIAssistant}
                width="100%"
                alt="Generate Engaging Social Posts Tailored To Your Target Audience In Seconds"
              />
            </Flex>

            <Flex
              pt="xxl"
              pb="xxl"
              mt={{ mobile: 0, tablet: 'l', desktop: 0 }}
              ml={{ mobile: 0, tablet: 'l' }}
              flexDirection="column"
              justifyContent="space-between"
              height={{ mobile: 'auto', tablet: '670px' }}
            >
              {GENERATE_SOCIAL_POSTS_ITEMS.map(({ description }, index) => (
                <Flex
                  flexDirection="column"
                  my={{ mobile: 'm', tablet: 0 }}
                  key={index}
                  maxWidth="540px"
                  width="100%"
                  alignItems="baseline"
                >
                  <Flex alignItems="baseline">
                    <Box borderRadius={radius.pill} width="8px" height="8px" minWidth="8px" bg={colors.primary} />
                    <Text ml="s" fontSize="xl" color={COLOR_CONSTANTS.DENIM} textAlign="left">
                      {description}
                    </Text>
                  </Flex>
                </Flex>
              ))}
            </Flex>
          </Flex>
        </Container>
      </Wrapper>

      <Wrapper bg={COLOR_CONSTANTS.SALT}>
        <Container
          pl={{ mobile: 'l', tablet: 'l' }}
          pr={{ mobile: 'l', tablet: 'l' }}
          py={{ mobile: 'l', tablet: 'xxl' }}
          position="relative"
          maxWidth={CONTAINER_MAX_WIDTH}
          width="100%"
          height="100%"
        >
          <Flex maxWidth="680px" flexDirection="column" mx="auto">
            <H3 fontSize="3xl" fontWeight="bold" color={COLOR_CONSTANTS.DENIM} textAlign="center">
              An Organized{' '}
              <H1 as="span" fontSize="3xl" fontWeight="bold" color="primary">
                Media Library
              </H1>
            </H3>
            <Text mt="m" pt="s" color="secondaryText" fontSize="l">
              Keep all your photos, videos, and user-generated content in one place, accessible on any device by your
              team.
            </Text>
          </Flex>
          <Flex
            mt="l"
            pt="m"
            justifyContent={{ mobile: 'center', desktop: 'space-between' }}
            flexDirection={{ mobile: 'column', desktop: 'row' }}
            alignItems="center"
          >
            <Flex mt={{ mobile: 'l', desktop: '0' }} order={{ mobile: 2, desktop: 2 }} maxWidth="550px" width="100%">
              <LocalImage src={imageMediaLibrary} width="100%" alt="An Organized Media Library" />
            </Flex>
            <Flex order={{ mobile: 1, desktop: 1 }}>
              <Grid gridGap="l" gridTemplateColumns={{ mobile: 'repeat(1, 1fr)', mobileLarge: 'repeat(2, 1fr)' }}>
                {MEDIA_LIBRARY_ITEMS.map(({ picture_url, title, description }) => (
                  <Flex
                    pl={{ mobile: '0', tablet: 'm' }}
                    ml={{ mobile: '0', tablet: 'xs' }}
                    flexDirection="column"
                    key={title}
                    maxWidth={{ mobile: '100%', tablet: '255px' }}
                    width="100%"
                  >
                    <Flex alignItems="center">
                      <LocalImage src={picture_url} width="44px" height="44px" alt={title} />
                      <Text ml="m" color={COLOR_CONSTANTS.DENIM} fontWeight="bold" fontSize="l">
                        {title}
                      </Text>
                    </Flex>
                    <Text mt="m" fontSize="m" color="secondaryText" textAlign="left">
                      {description}
                    </Text>
                  </Flex>
                ))}
              </Grid>
            </Flex>
          </Flex>
        </Container>
      </Wrapper>

      <Wrapper>
        <Container
          pl={{ mobile: 'l', tablet: 'l' }}
          pr={{ mobile: 'l', tablet: 'l' }}
          py={{ mobile: 'l', tablet: 'xxl' }}
          position="relative"
          maxWidth={CONTAINER_MAX_WIDTH}
          width="100%"
          height="100%"
        >
          <Flex maxWidth="680px" flexDirection="column" mx="auto">
            <H3 fontSize="3xl" fontWeight="bold" color={COLOR_CONSTANTS.DENIM} textAlign="center">
              Find + Collect{' '}
              <H1 as="span" fontSize="3xl" fontWeight="bold" color="primary">
                UGC
              </H1>
            </H3>
            <Text mt="m" pt="s" color="secondaryText" fontSize="l">
              Search trending search media content and add it directly to your media library to schedule or repost into
              your own feed.
            </Text>
          </Flex>
          <Flex
            mt="l"
            pt="m"
            justifyContent={{ mobile: 'center', desktop: 'space-between' }}
            flexDirection={{ mobile: 'column', desktop: 'row' }}
            alignItems="center"
          >
            <Flex mt={{ mobile: 'l', desktop: '0' }} order={{ mobile: 2, desktop: 2 }} maxWidth="677px" width="100%">
              <LocalImage src={imageUGC} width="100%" alt="Find + Collect UGC" />
            </Flex>
            <Flex order={{ mobile: 1, desktop: 1 }}>
              <Grid gridGap="l" gridTemplateColumns="repeat(1, 1fr)">
                {UGC_ITEMS.map(({ title, description }) => (
                  <Flex flexDirection="column" key={title} maxWidth="350px" width="100%">
                    <Flex alignItems="center">
                      <Box borderRadius={radius.pill} width="8px" height="8px" minWidth="8px" bg={colors.primary} />
                      <Text ml="s" color={COLOR_CONSTANTS.DENIM} fontWeight="bold" fontSize="xl">
                        {title}
                      </Text>
                    </Flex>
                    <Text mt="m" fontSize="m" color="secondaryText" textAlign="left">
                      {description}
                    </Text>
                  </Flex>
                ))}
              </Grid>
            </Flex>
          </Flex>
        </Container>
      </Wrapper>

      <Wrapper bg={COLOR_CONSTANTS.SALT}>
        <Container
          pl={{ mobile: 'l', tablet: 'l' }}
          pr={{ mobile: 'l', tablet: 'l' }}
          py={{ mobile: 'l', tablet: 'xxl' }}
          position="relative"
          maxWidth={CONTAINER_MAX_WIDTH}
          width="100%"
          height="100%"
        >
          <Flex
            justifyContent={{ mobile: 'center', desktop: 'space-between' }}
            flexDirection={{ mobile: 'column', desktop: 'row' }}
            alignItems="center"
          >
            <Flex mt={{ mobile: 'l', desktop: '0' }} order={{ mobile: 2, desktop: 2 }} maxWidth="529px" width="100%">
              <LocalImage src={imageCollaborateOnContent} width="100%" alt="Collaborate on content with your team" />
            </Flex>
            <Flex
              order={{ mobile: 1, desktop: 1 }}
              flexDirection="column"
              alignItems={{ mobile: 'center', desktop: 'flex-start' }}
              maxWidth="540px"
            >
              <Text
                fontWeight="bold"
                fontSize="3xl"
                color={COLOR_CONSTANTS.DENIM}
                textAlign={{ mobile: 'center', desktop: 'left' }}
              >
                Collaborate on content <br />
                <Text as="span" fontSize="3xl" fontWeight="bold" color="primary">
                  with your team
                </Text>
              </Text>

              <Text mt="m" pt="s" color="secondaryText" fontSize="l" textAlign={{ mobile: 'center', desktop: 'left' }}>
                Work together with your team and clients to create content that's high quality, on-brand, and that your
                audience is going to love.
              </Text>
              <Grid
                mt="l"
                pt="m"
                gridGap="l"
                gridTemplateColumns={{ mobile: 'repeat(1, 1fr)', mobileLarge: 'repeat(2, 1fr)' }}
              >
                {COLLABORATE_ON_CONTENT_ITEMS.map(({ picture_url, title, description }) => (
                  <Flex flexDirection="column" key={title} maxWidth={{ mobile: '100%', tablet: '255px' }} width="100%">
                    <LocalImage src={picture_url} width="60px" height="60px" alt={title} />

                    <Text mt="m" color={COLOR_CONSTANTS.DENIM} fontWeight="bold" fontSize="l" textAlign="left">
                      {title}
                    </Text>
                    <Text mt="s" fontSize="m" color="secondaryText" textAlign="left">
                      {description}
                    </Text>
                  </Flex>
                ))}
              </Grid>
            </Flex>
          </Flex>
        </Container>
      </Wrapper>

      <Wrapper>
        <Container
          pl={{ mobile: 'l', tablet: 'l' }}
          pr={{ mobile: 'l', tablet: 'l' }}
          py={{ mobile: 'l', tablet: 'xxl' }}
          position="relative"
          maxWidth={CONTAINER_MAX_WIDTH}
          width="100%"
          height="100%"
        >
          <Flex maxWidth="680px" flexDirection="column" mx="auto">
            <H3 fontSize="3xl" fontWeight="bold" color={COLOR_CONSTANTS.DENIM} textAlign="center">
              <H1 as="span" fontSize="3xl" fontWeight="bold" color="primary">
                Plan out{' '}
              </H1>
              the best posting schedule
            </H3>
            <Text mt="m" pt="s" color="secondaryText" fontSize="l">
              Schedule content to post using a schedule created based on optimal time for each social profile
            </Text>
          </Flex>

          <Flex
            justifyContent={{ mobile: 'center', desktop: 'space-between' }}
            flexDirection={{ mobile: 'column', desktop: 'row' }}
            alignItems="center"
            mt="l"
            pt="m"
          >
            <Flex mt={{ mobile: 'l', desktop: '0' }} order={{ mobile: 2, desktop: 2 }} maxWidth="750px" width="100%">
              <LocalImage src={imagePlanPostingSchedule} width="100%" alt="Plan out the best posting schedule" />
            </Flex>
            <Flex
              order={{ mobile: 1, desktop: 1 }}
              flexDirection="column"
              alignItems={{ mobile: 'center', desktop: 'flex-start' }}
              maxWidth={{ mobile: '100%', desktop: '270px' }}
            >
              <Grid
                gridGap="56px"
                gridTemplateColumns={{
                  mobile: 'repeat(1, 1fr)',
                  tablet: 'repeat(3, 1fr)',
                  desktop: 'repeat(1, 1fr)',
                }}
              >
                {PLAN_OUT_SCHEDULE_ITEMS.map(({ title, description }) => (
                  <Flex key={title}>
                    <Flex
                      width="8px"
                      bg={colors.primary}
                      minWidth="8px"
                      borderRadius={`0px ${radius.l} ${radius.l} 0px`}
                    />
                    <Flex ml="m" flexDirection="column">
                      <Text fontWeight="bold" fontSize="l" textAlign="left" color={COLOR_CONSTANTS.DENIM}>
                        {title}
                      </Text>
                      <Text mt="s" fontSize="m" color="secondaryText" textAlign="left">
                        {description}
                      </Text>
                    </Flex>
                  </Flex>
                ))}
              </Grid>
            </Flex>
          </Flex>
        </Container>
      </Wrapper>

      <Wrapper bg="radial-gradient(51.88% 56.86% at 31.54% 45.31%, #0063E3 36.77%, #0250C9 100%)">
        <Container
          pl={{ mobile: 'l', tablet: 'l' }}
          pr={{ mobile: 'l', tablet: 'l' }}
          py={{ mobile: 'l', tablet: 'xxl' }}
          position="relative"
          maxWidth={CONTAINER_MAX_WIDTH}
          width="100%"
          height="100%"
        >
          <Flex maxWidth="730px" flexDirection="column" mx="auto">
            <H3 fontSize="3xl" fontWeight="bold" color={COLOR_CONSTANTS.WHITE} textAlign="center">
              Post, Schedule, and Plan{' '}
              <H1 as="span" fontSize="3xl" fontWeight="normal" color={COLOR_CONSTANTS.WHITE} fontStyle="italic">
                from Anywhere
              </H1>
            </H3>
            <Text mt="m" pt="s" color="white" fontSize="l">
              Download Vista Social’s mobile app and save time managing all of your social media channels anytime, no
              matter where you are.
            </Text>
          </Flex>

          <Flex mt="xl" justifyContent={{ mobile: 'center', desktop: 'space-between' }} as="a" href="/mobile">
            <LocalImage
              src={imagePostScheduleAndPlan}
              maxWidth="1008px"
              width="100%"
              mx="auto"
              alt="Post, Schedule, and Plan from Anywhere"
            />
          </Flex>
        </Container>
      </Wrapper>

      <Wrapper>
        <Container
          pl={{ mobile: 'l', tablet: 'l' }}
          pr={{ mobile: 'l', tablet: 'l' }}
          py={{ mobile: 'l', tablet: 'xxl' }}
          position="relative"
          maxWidth={CONTAINER_MAX_WIDTH}
          width="100%"
          height="100%"
        >
          <Flex
            justifyContent={{ mobile: 'center', desktop: 'space-between' }}
            flexDirection={{ mobile: 'column', desktop: 'row' }}
            alignItems="center"
          >
            <Flex mt={{ mobile: 'l', desktop: '0' }} order={{ mobile: 2, desktop: 2 }} maxWidth="627px" width="100%">
              <LocalImage src={imageBrowserExtension} width="100%" alt="Browser Extension" />
            </Flex>
            <Flex
              order={{ mobile: 1, desktop: 1 }}
              flexDirection="column"
              alignItems={{ mobile: 'center', desktop: 'flex-start' }}
              maxWidth="445px"
            >
              <Text fontWeight="bold" fontSize="3xl" color={COLOR_CONSTANTS.DENIM}>
                Browser Extension
              </Text>
              <Text mt="m" pt="s" color="secondaryText" fontSize="l" textAlign={{ mobile: 'center', desktop: 'left' }}>
                The free Vista Social Chrome and Firefox Extensions makes it super easy for you to curate and collect
                photos, videos and sounds from anywhere on the web to be used in your posts. The best part? You can
                schedule them right from the web! Vista Social maintains the highest photo quality, so you can say
                goodbye to pixelated photos from screenshots.
              </Text>
              <StyledLinkText mt="l" pt="m" as="a" href="/extensions" textAlign="left">
                <Text fontSize="l" fontWeight="medium" textAlign="left" color="primary" as="span">
                  READ MORE
                </Text>
                <LocalImage src={imageDoubleArrowRightBlue} mt="-2px" />
              </StyledLinkText>
            </Flex>
          </Flex>
        </Container>
      </Wrapper>

      <CarouselReviewSection />

      <SocialMediasSection />

      <Wrapper bg={COLOR_CONSTANTS.SALT}>
        <Container
          pl={{ mobile: 'l', tablet: 'l' }}
          pr={{ mobile: 'l', tablet: 'l' }}
          py={{ mobile: 'l', tablet: 'xxl' }}
          position="relative"
          maxWidth={CONTAINER_MAX_WIDTH}
          width="100%"
          height="100%"
        >
          <H3 fontSize="3xl" fontWeight="bold" color={COLOR_CONSTANTS.DENIM} textAlign="center">
            What’s included:{' '}
            <H1 as="span" fontSize="3xl" fontWeight="bold" color="primary">
              Core Features
            </H1>
          </H3>
          <Grid
            mt="xl"
            gridGap="m"
            gridTemplateColumns={{
              mobile: 'repeat(1, 1fr)',
              mobileLarge: 'repeat(2, 1fr)',
              tablet: 'repeat(3, 1fr)',
              desktop: 'repeat(4, 1fr)',
            }}
          >
            {FEATURE_ITEMS.map(({ title, description, picture_url }) => {
              return (
                <Flex
                  flexDirection="column"
                  key={title}
                  width="100%"
                  bg={COLOR_CONSTANTS.WHITE}
                  borderRadius={radius.l}
                  py="s"
                  px="s"
                >
                  <Flex alignItems="center">
                    <LocalImage src={picture_url} width="44px" height="44px" alt={title} />
                    <Text ml="s" color={COLOR_CONSTANTS.DENIM} fontWeight="bold" fontSize="l">
                      {title}
                    </Text>
                  </Flex>
                  <Text mt="m" fontSize="m" color="secondaryText" textAlign="left">
                    {description}
                  </Text>
                </Flex>
              )
            })}
          </Grid>
        </Container>
      </Wrapper>

      <Wrapper>
        <Container
          pl={{ mobile: 'l', tablet: 'l' }}
          pr={{ mobile: 'l', tablet: 'l' }}
          py={{ mobile: 'l', tablet: 'xxl' }}
          position="relative"
          maxWidth={CONTAINER_MAX_WIDTH}
          width="100%"
          height="100%"
        >
          <H3 fontSize="3xl" fontWeight="bold" color={COLOR_CONSTANTS.DENIM} textAlign="center">
            Frequently Asked{' '}
            <H3 as="span" fontSize="3xl" fontWeight="bold" color="primary">
              Questions
            </H3>
          </H3>
          <Flex width="100%" mt="xl">
            <QuestionAnswerComponent />
          </Flex>
          <Flex flexDirection="column" mt="xl" width="100%">
            <GridDropdown faqs={FREQUENTLY_ASKED_QUESTIONS.publish} />
            <Flex mt="l" justifyContent="flex-end">
              <StyledLinkText as="a" href="/faq" textAlign="left">
                <Text fontSize="l" fontWeight="medium" textAlign="left" color="primary" as="span">
                  ALL OUR FAQS
                </Text>
                <LocalImage src={imageDoubleArrowRightBlue} mt="-2px" />
              </StyledLinkText>
            </Flex>
          </Flex>
        </Container>
      </Wrapper>

      <GetStartedComponent activeKey={PUBLISHING} />

      <Footer />
    </App>
  )
}

export default Publish
